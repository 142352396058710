import React, { useState, useEffect } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './ClientDirectory.css'; // Import the CSS for styling
import axios from 'axios'; // Make sure to install axios if not already

const ClientDirectory = () => {
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  //Handler to fetch data for the table
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        // Implement navigation or alert for no token
        console.error('No access token found');
        return;
      }

      try {
        const response = await axios.get('https://app.trackmydocs.cloud/api/clients/client-table/', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedClients(clients.map(client => client.id));
    } else {
      setSelectedClients([]);
    }
  };

  const handleRowSelect = (client) => {
    const newSelection = selectedClients.includes(client.id)
      ? selectedClients.filter(id => id !== client.id)
      : [...selectedClients, client.id];
    setSelectedClients(newSelection);
  };

  const handleDeleteSelected = () => {
    // Implement deletion logic
    axios.post('/api/delete-clients', { ids: selectedClients }) // Adjust the URL and method based on your API
      .then(() => {
        setClients(clients.filter(client => !selectedClients.includes(client.id)));
        setSelectedClients([]);
      })
      .catch(error => console.error('Error deleting clients:', error));
  };

  const filteredClients = clients.filter(client =>
    client.given_name.toLowerCase().includes(searchInput.toLowerCase()) ||
    client.surname.toLowerCase().includes(searchInput.toLowerCase()) ||
    client.email.toLowerCase().includes(searchInput.toLowerCase()) ||
    client.contact_number.includes(searchInput)
  );

  return (
    <div className="cd-container">
      <DashboardHeader />
      <Sidebar />
      <div className="cd-main-content">
        <div className="cd-content-area">
          <div className="cd-recent-clients">
            <div className="cd-recent-clients-header">
              <h2>Clients</h2>
              <input
                type="text"
                className="cd-search-bar"
                placeholder="Search clients..."
                onChange={handleSearchChange}
                value={searchInput}
              />
              {selectedClients.length > 0 && (
                <button 
                  className="cd-case-row-delete"
                  onClick={handleDeleteSelected}
                >
                  Delete Selected
                </button>
              )}
            </div>
            <table className="cd-clients-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedClients.length === clients.length && clients.length > 0}
                    />
                  </th>
                  <th>ID</th>
                  <th>Client Type</th>
                  <th>Given Name</th>
                  <th>Surname</th>
                  <th>Email</th>
                  <th>Contact Number</th>
                  <th>Gender</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.length > 0 ? (
                  filteredClients.map((client) => (
                    <tr key={client.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedClients.includes(client.id)}
                          onChange={() => handleRowSelect(client)}
                        />
                      </td>
                      <td>{client.id}</td>
                      <td>{client.client_type}</td>
                      <td>{client.given_name}</td>
                      <td>{client.surname}</td>
                      <td>{client.email}</td>
                      <td>{client.contact_number}</td>
                      <td>{client.gender}</td>
                      <td>{new Date(client.created_at).toLocaleDateString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">No clients found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default ClientDirectory;
