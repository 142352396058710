import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './SignIn';
import Dashboard from './pages/Dashboard';
import MainFooter from './components/MainFooter';
import NewClient from './pages/NewClient';
import CaseDetail from './components/CaseDetail';
import Invoices from './components/Invoices';
import axios from 'axios';
import InvoiceManagement from './pages/InvoiceManagement';
import InvoiceInfo from './components/InvoiceInfo';  // Adjust path if necessary
import ClientDirectory from './pages/ClientDirectory';
import Documents from './pages/Documents';


function App() {
  // Add Axios interceptors for logging requests and responses
  useEffect(() => {
    // Request interceptor
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        console.log('Request URL:', config.url);
        console.log('Request Method:', config.method);
        console.log('Request Headers:', config.headers);
        console.log('Request Data:', config.data);
        return config;
      },
      (error) => {
        console.error('Request Error:', error);
        return Promise.reject(error);
      }
    );

    // Response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        console.log('Response URL:', response.config.url);
        console.log('Response Data:', response.data);
        return response;
      },
      (error) => {
        console.error('Response Error:', error);
        return Promise.reject(error);
      }
    );

    // Cleanup interceptors on component unmount
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <Router>
      <div className="container">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div className="content">
                <img src="/logo.png" alt="TrackMyDocs Logo" className="logo" />
                <h1>Website Under Construction</h1>
                <p>We're working hard to bring you a great experience. Stay tuned!</p>
                <MainFooter />
              </div>
            }
          />
          <Route
            path="/signin"
            element={
              <>
                <SignIn />
              </>
            }
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/new-client" element={<NewClient />} />
          <Route path="/cases/:case_number" element={<CaseDetail />} />
          <Route path="/gen-invoice/:case_number" element={<Invoices />} />
          <Route path="/invoice-management" element={<InvoiceManagement />} />
          <Route path="/disp-invoice/:invoice_number" element={<InvoiceInfo />} />
          <Route path="/client-directory" element={<ClientDirectory />} />
          <Route path="/documents" element={<Documents />} />           
        </Routes>
      </div>
    </Router>
  );
}

export default App;
