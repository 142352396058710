import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './InvoiceManagement.css'; // Import the CSS for styling
import { useNavigate } from 'react-router-dom';

const InvoiceManagement = () => {
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No JWT token found, redirecting to login.');
        return;
      }
      try {
        const response = await axios.get('https://app.trackmydocs.cloud/api/invoices/invoice-table/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Sort invoices by invoice number before setting state
        const sortedInvoices = response.data.sort((a, b) => a.invoice_number.localeCompare(b.invoice_number));
        console.log(sortedInvoices); // Check what's being stored in state
        setInvoices(sortedInvoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices(); // Call the function on component mount
  }, []);

  const handleRowClick = (invoiceNumber) => {
    navigate(`/disp-invoice/${invoiceNumber}`);
  };

  return (
    <div className="im-container">
      <DashboardHeader />
      <Sidebar />
      <div className="im-main-content">
        <div className="im-content-area">
          
          {/* Invoice Management Recent Invoices Div */}
          <div className="im-recent-invoices">
            <div className="im-recent-invoices-header">
              <h2>Recent Invoices</h2>
              <input
                type="text"
                className="im-search-bar"
                placeholder="Search invoices..."
              />
            </div>
            <table className="im-invoices-table">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Our Reference</th>
                  <th>Client</th>
                </tr>
              </thead>
              <tbody>
                {invoices.length > 0 ? (
                invoices.map((invoice) => (
                  <tr key={invoice.invoice_number} onClick={() => handleRowClick(invoice.invoice_number)}>
                      <td>{invoice.invoice_number}</td>
                      <td>£{invoice.total_amount ? parseFloat(invoice.total_amount).toFixed(2) : '0.00'}</td>
                      <td>{invoice.status}</td>
                      <td>{invoice.our_ref}</td>
                      <td>{invoice.client}</td>
                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan="5">No invoices found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default InvoiceManagement;
