import React, { useState, useEffect } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './NewClient.css'; // Assuming the same styles

const NewClient = () => {
    const initialFormData = {
        clientType: 'Landlord',
        c_title: '',
        givenName: '',
        surname: '',
        email: '',
        contactNumber: '',
        gender: '',
        no: '',
        address: '',
        district: '',
        city: '',
        postcode: '',
        t_title: '',
        t_givenName: '',
        t_surname: '',
        t_email: '',
        t_contactNumber: '',
        t_gender: '',
        t_no: '',
        t_street: '',
        t_city: '',
        t_postcode: '',
        t_district: '',
    };

    // Reset Primary Landlord Form
    const resetPrimaryLandlordForm = () => {
        setFormData({
            ...formData,
            c_title: '',
            givenName: '',
            surname: '',
            email: '',
            contactNumber: '',
            gender: '',
            no: '',
            address: '',
            district: '',
            city: '',
            postcode: ''
        });
    };

    // Reset Primary Tenant Form
    const resetPrimaryTenantForm = () => {
        setFormData({
            ...formData,
            t_title: '',
            t_givenName: '',
            t_surname: '',
            t_email: '',
            t_contactNumber: '',
            t_gender: '',
            t_no: '',
            t_street: '',
            t_district: '',
            t_city: '',
            t_postcode: ''
        });
    };

    // Retrieve saved form data from localStorage if available
    //const savedFormData = JSON.parse(localStorage.getItem('formData')) || initialFormData;
    const [formData, setFormData] = useState(initialFormData);
    //const [clientType, setClientType] = useState('Landlord');
    const [landlords, setLandlords] = useState([]);
    const [tenants, setTenants] = useState([]);

    // Save form data to localStorage on each change
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);

    //Handler to refresh token every hour
    useEffect(() => {
        // Refresh token every hour
        const refreshInterval = setInterval(() => {
            refreshAuthToken();
        }, 60 * 60 * 1000); // 1 hour interval
    
        return () => clearInterval(refreshInterval); // Cleanup on component unmount
    }, []);    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value || '' }));
    };


    const addLandlord = () => {
        const isPrimary = landlords.length === 0;
        const newLandlord = isPrimary ? {
            id: landlords.length + 1,
            c_title: formData.c_title,
            givenName: formData.givenName,
            surname: formData.surname,
            email: formData.email,
            contactNumber: formData.contactNumber,
            gender: formData.gender,
            no: formData.no,
            address: formData.address,
            district: formData.district,
            city: formData.city,
            postcode: formData.postcode
        } : {
            id: landlords.length + 1,
            addland_title: formData.addland_title,
            addland_given_name: formData.addland_given_name,
            addland_surname: formData.addland_surname,
            addland_email: formData.addland_email,
            addland_contact_number: formData.addland_contact_number
        };
    
        setLandlords(prev => [...prev, newLandlord]);
        console.log('Adding new landlord:', newLandlord);

        // Reset both primary and additional landlord fields
        setFormData(prev => ({
            ...prev,
            c_title: '', givenName: '', surname: '', email: '', contactNumber: '', gender: '', no: '', address: '', district: '', city: '', postcode: '',
            addland_title: '', addland_given_name: '', addland_surname: '', addland_email: '', addland_contact_number: ''
        }));
    };
    
    
    

    const addTenant = () => {
        const isPrimary = tenants.length === 0;
        const newTenant = isPrimary ? {
            id: tenants.length + 1,
            t_title: formData.t_title,
            t_givenName: formData.t_givenName,
            t_surname: formData.t_surname,
            t_email: formData.t_email,
            t_contactNumber: formData.t_contactNumber,
            t_gender: formData.t_gender,
            t_no: formData.t_no,
            t_street: formData.t_street,
            t_city: formData.t_city,
            t_postcode: formData.t_postcode,
            t_district: formData.t_district
        } : {
            id: tenants.length + 1,
            addtent_title: formData.addtent_title,
            addtent_given_name: formData.addtent_given_name,
            addtent_surname: formData.addtent_surname,
            addtent_email: formData.addtent_email,
            addtent_contact_number: formData.addtent_contact_number
        };
    
        setTenants(prev => [...prev, newTenant]);
        console.log('Adding new tenant:', newTenant);

        // Reset both primary and additional tenant fields
        setFormData(prev => ({
            ...prev,
            t_title: '', t_givenName: '', t_surname: '', t_email: '', t_contactNumber: '', t_gender: '', t_no: '', t_street: '', t_city: '', t_postcode: '', t_district: '',
            addtent_title: '', addtent_given_name: '', addtent_surname: '', addtent_email: '', addtent_contact_number: ''
        }));
    };
    
    
    

    // Delete Landlord Logic
    const deleteLandlord = (id) => {
        const remainingLandlords = landlords.filter(landlord => landlord.id !== id);
        setLandlords(remainingLandlords);

        if (id === 1 && remainingLandlords.length === 0) {
            resetPrimaryLandlordForm();  // Bring back the primary landlord form when the first landlord is deleted
        } else if (remainingLandlords.length === 0) {
            setFormData({
                ...formData,
                addland_title: '',
                addland_given_name: '',
                addland_surname: '',
                addland_email: '',
                addland_contact_number: ''
            });  // Bring back additional landlord fields when all additional landlords are deleted
        }
    };

    // Delete Tenant Logic
    const deleteTenant = (id) => {
        const remainingTenants = tenants.filter(tenant => tenant.id !== id);
        setTenants(remainingTenants);

        if (id === 1 && remainingTenants.length === 0) {
            resetPrimaryTenantForm();  // Bring back the primary tenant form when the first tenant is deleted
        } else if (remainingTenants.length === 0) {
            setFormData({
                ...formData,
                addtent_title: '',
                addtent_given_name: '',
                addtent_surname: '',
                addtent_email: '',
                addtent_contact_number: ''
            });  // Bring back additional tenant fields when all additional tenants are deleted
        }
    };

    //Function to extend token expiry to 8 hours 
    const decodeTokenExpiration = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );
        const decodedToken = JSON.parse(jsonPayload);
        return decodedToken.exp; // Return the expiration time
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('access_token');
        if (storedToken) {
            const tokenExpiration = decodeTokenExpiration(storedToken);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            
            if (tokenExpiration - currentTime > 8 * 60 * 60) { // 8 hours = 28800 seconds
                // Keep the session active or alert user to re-login
                setTimeout(() => {
                    alert('Session is about to expire. Please re-login.');
                    window.location.href = '/signin'; // Redirect to sign page
                }, (tokenExpiration - currentTime - 5) * 1000); // Prompt 5 seconds before expiration
            }
        }
    }, []); // Run once on component mount

    const refreshAuthToken = async () => {
        const refreshToken = localStorage.getItem('refresh_token');
        try {
            const response = await fetch('https://app.trackmydocs.cloud/api/auth/refresh/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: refreshToken })
            });
            const data = await response.json();
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
            } else {
                console.log('Token refresh failed');
            }
        } catch (error) {
            console.error('Error refreshing token', error);
        }
    };
    
    useEffect(() => {
        // Refresh token every hour (for example)
        const refreshInterval = setInterval(() => {
            refreshAuthToken();
        }, 60 * 60 * 1000); // 1 hour interval
    
        return () => clearInterval(refreshInterval); // Cleanup on component unmount
    }, []);    

    // Handler Function to create New Case with Landlords & Tenants provided
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Retrieve token from localStorage
        const token = localStorage.getItem('access_token'); // Make sure the token is available here
    
        console.log('Form Data:', formData);
    
        if (!token) {
            console.error('No token available for the request!');
            alert('You are not authenticated. Please log in again.');
            return;
        }
    
        // Separate the first landlord and tenant from the additional ones
        const primaryLandlord = landlords[0]; // First landlord (Primary)
        const additionalLandlords = landlords.slice(1); // Remaining landlords (Additional)
    
        const primaryTenant = tenants[0]; // First tenant (Primary)
        const additionalTenants = tenants.slice(1); // Remaining tenants (Additional)
    
        // Format the primary landlord's address (keep address fields for the primary landlord)
        const primaryLandlordAddress = `${primaryLandlord.no} ${primaryLandlord.address}, ${primaryLandlord.district}`;
    
        // Format the primary tenant's address (keep address fields for the primary tenant)
        const primaryTenantAddress = `${primaryTenant.t_no} ${primaryTenant.t_street}, ${primaryTenant.t_district}, ${primaryTenant.t_city}, ${primaryTenant.t_postcode}`;
    
        // Prepare additional landlords data (no address fields)
        const additionalLandlordsData = additionalLandlords.map(landlord => ({
            addland_title: landlord.addland_title,
            addland_given_name: landlord.addland_given_name,
            addland_surname: landlord.addland_surname,
            addland_email: landlord.addland_email,
            addland_contact_number: landlord.addland_contact_number,
        }));
    
        // Prepare additional tenants data (no address fields)
        const additionalTenantsData = additionalTenants.map(tenant => ({
            addtent_title: tenant.addtent_title,
            addtent_given_name: tenant.addtent_given_name,
            addtent_surname: tenant.addtent_surname,
            addtent_email: tenant.addtent_email,
            addtent_contact_number: tenant.addtent_contact_number,
        }));
    
        try {
            console.log('Payload to be sent:', JSON.stringify({
                client_type: formData.clientType,
                // Primary landlord data
                c_title: primaryLandlord.c_title,
                given_name: primaryLandlord.givenName,
                surname: primaryLandlord.surname,
                email: primaryLandlord.email,
                contact_number: primaryLandlord.contactNumber,
                gender: primaryLandlord.gender,
                address: primaryLandlordAddress,  // Send the concatenated address for the primary landlord
                city: primaryLandlord.city,
                postcode: primaryLandlord.postcode,
    
                // Primary tenant data should be inside the "tenant" field
                tenant: {
                    t_title: primaryTenant.t_title,
                    t_given_name: primaryTenant.t_givenName,
                    t_surname: primaryTenant.t_surname,
                    t_email: primaryTenant.t_email,
                    t_contact_number: primaryTenant.t_contactNumber,
                    t_gender: primaryTenant.t_gender,
                    property_address: primaryTenantAddress,  // Send the concatenated address for the primary tenant
                },
    
                // Additional landlords and tenants arrays
                additional_landlords: additionalLandlordsData,  // No address fields for additional landlords
                additional_tenants: additionalTenantsData  // No address fields for additional tenants
            }));
    
            const response = await fetch('https://app.trackmydocs.cloud/api/clients/clients/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // Include the JWT token here
                },
                body: JSON.stringify({
                    client_type: formData.clientType,
                    // Primary landlord data
                    c_title: primaryLandlord.c_title,
                    given_name: primaryLandlord.givenName,
                    surname: primaryLandlord.surname,
                    email: primaryLandlord.email,
                    contact_number: primaryLandlord.contactNumber,
                    gender: primaryLandlord.gender,
                    address: primaryLandlordAddress,  // Send the concatenated address for the primary landlord
                    city: primaryLandlord.city,
                    postcode: primaryLandlord.postcode,
    
                    // Now send the tenant data in a tenant object
                    tenant: {
                        t_title: primaryTenant.t_title,
                        t_given_name: primaryTenant.t_givenName,
                        t_surname: primaryTenant.t_surname,
                        t_email: primaryTenant.t_email,
                        t_contact_number: primaryTenant.t_contactNumber,
                        t_gender: primaryTenant.t_gender,
                        property_address: primaryTenantAddress,  // Send the concatenated address for the primary tenant
                    },
    
                    // Additional landlords and tenants arrays
                    additional_landlords: additionalLandlordsData,  // No address fields for additional landlords
                    additional_tenants: additionalTenantsData  // No address fields for additional tenants
                }),
            });
    
            const result = await response.json();
            console.log('Response:', result);
    
            if (response.ok) {
                alert('Client and additional landlords/tenants created successfully!');
                localStorage.removeItem('newClientFormData'); // Clear form data
                setLandlords([]); // Clear landlords array
                setTenants([]);   // Clear tenants array
                setFormData(initialFormData); // Reset form data
                window.location.href = '/dashboard'; // Redirect to dashboard
            } else {
                alert(`Failed to create client: ${result.detail || JSON.stringify(result)}`);
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };
    

    

    return (
        <div className="dashboard-container">
            <DashboardHeader />
            <Sidebar />
            <div className="main-content">
                <div className="content-area">
                    <div className="new-client">
                        <div className="nc-three-columns">
                            <div className="nc-form-column">
                                <form className="new-client-form" onSubmit={handleSubmit}>
                                  
                                    {landlords.length === 0 ? (
                                        <>
                                            <h3>New Client - Landlord</h3>
                                            <div className="form-group">
                                                <label htmlFor="clientType">Client Type:</label>
                                                <select 
                                                    id="clientType" 
                                                    name="clientType" 
                                                    value={formData.clientType} 
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="Landlord">Landlord</option>
                                                    <option value="Organisation">Organisation</option>
                                                    <option value="Business">Business</option>
                                                    <option value="Trust">Trust</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="c_title">Title:</label>
                                                <select
                                                    id="c_title"
                                                    name="c_title"
                                                    value={formData.c_title}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="givenName">Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="givenName" 
                                                    name="givenName" 
                                                    value={formData.givenName}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="surname">Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="surname" 
                                                    name="surname"
                                                    value={formData.surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="email" 
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contactNumber">Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="contactNumber" 
                                                    name="contactNumber"
                                                    value={formData.contactNumber}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Gender:</label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="gender" 
                                                        value="Male" 
                                                        checked={formData.gender === 'Male'}
                                                        onChange={handleInputChange}
                                                    /> Male
                                                </label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="gender" 
                                                        value="Female"
                                                        checked={formData.gender === 'Female'}
                                                        onChange={handleInputChange}
                                                    /> Female
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="No">No:</label>
                                                <input 
                                                    type="text" 
                                                    id="no" 
                                                    name="no" 
                                                    value={formData.no}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="address">Street:</label>
                                                <input 
                                                    type="text" 
                                                    id="address" 
                                                    name="address" 
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="district">District:</label>
                                                <input 
                                                    type="text" 
                                                    id="district" 
                                                    name="district" 
                                                    value={formData.district}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="city">Town / City:</label>
                                                <input 
                                                    type="text" 
                                                    id="city" 
                                                    name="city" 
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="postcode">Postcode:</label>
                                                <input 
                                                    type="text" 
                                                    id="postcode" 
                                                    name="postcode" 
                                                    value={formData.postcode}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        // Additional Landlord fields
                                        <>
                                            <h3>Additional Landlord</h3>
                                            <div className="form-group">
                                                <label htmlFor="addland_title">Title:</label>
                                                <select 
                                                    id="addland_title" 
                                                    name="addland_title" 
                                                    value={formData.addland_title} 
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_given_name">Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="addland_given_name" 
                                                    name="addland_given_name" 
                                                    value={formData.addland_given_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_surname">Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="addland_surname" 
                                                    name="addland_surname" 
                                                    value={formData.addland_surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_email">Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="addland_email" 
                                                    name="addland_email"
                                                    value={formData.addland_email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_contact_number">Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="addland_contact_number" 
                                                    name="addland_contact_number"
                                                    value={formData.addland_contact_number}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </>
                                    )}

                                    
                                    {tenants.length === 0 ? (
                                        <>
                                            <h3>New Tenant</h3>
                                            <div className="form-group">
                                                <label htmlFor="t_title">Tenant Title:</label>
                                                <select 
                                                    id="t_title" 
                                                    name="t_title" 
                                                    value={formData.t_title} 
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_givenName">Tenant Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_givenName" 
                                                    name="t_givenName" 
                                                    value={formData.t_givenName}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_surname">Tenant Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_surname" 
                                                    name="t_surname" 
                                                    value={formData.t_surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_email">Tenant Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="t_email" 
                                                    name="t_email"
                                                    value={formData.t_email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_contactNumber">Tenant Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_contactNumber" 
                                                    name="t_contactNumber"
                                                    value={formData.t_contactNumber}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Tenant Gender:</label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="t_gender" 
                                                        value="Male" 
                                                        checked={formData.t_gender === 'Male'}
                                                        onChange={handleInputChange}
                                                    /> Male
                                                </label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="t_gender" 
                                                        value="Female"
                                                        checked={formData.t_gender === 'Female'}
                                                        onChange={handleInputChange}
                                                    /> Female
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_no">No:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_no" 
                                                    name="t_no" 
                                                    value={formData.t_no}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_street">Street:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_street" 
                                                    name="t_street" 
                                                    value={formData.t_street}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_district">District:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_district" 
                                                    name="t_district" 
                                                    value={formData.t_district}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_city">Town / City:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_city" 
                                                    name="t_city" 
                                                    value={formData.t_city}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_postcode">Postcode:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_postcode" 
                                                    name="t_postcode" 
                                                    value={formData.t_postcode}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        // Additional Tenant fields
                                        <>
                                            <h3>Additional Tenant</h3>
                                            <div className="form-group">
                                                <label htmlFor="addtent_title">Title:</label>
                                                <select 
                                                    id="addtent_title" 
                                                    name="addtent_title" 
                                                    value={formData.addtent_title} 
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_given_name">Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="addtent_given_name" 
                                                    name="addtent_given_name" 
                                                    value={formData.addtent_given_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_surname">Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="addtent_surname" 
                                                    name="addtent_surname" 
                                                    value={formData.addtent_surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_email">Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="addtent_email" 
                                                    name="addtent_email"
                                                    value={formData.addtent_email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_contact_number">Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="addtent_contact_number" 
                                                    name="addtent_contact_number"
                                                    value={formData.addtent_contact_number}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="form-group">
                                        <button type="submit" className="submit-button">Submit</button>
                                        <button type="button" className="new-client-clear-button" onClick={() => setFormData(initialFormData)}>Clear Form</button>
                                    </div>
                                </form>
                            </div>
                            <div className="nc-vertical-divider"></div>
                            <div className="nc-add-additional-client">
                                <div className="nc-add-landlord">
                                    <div className="nc-add-c-button" onClick={addLandlord}>+</div>
                                    <p>Add Landlord</p>
                                    <div className="nc-landlord-array-table">
                                        <table className="nc-landlord-at-table">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Landlord</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {landlords.map(landlord => (
                                                    <tr key={landlord.id}>
                                                        <td>{landlord.id}</td>
                                                        <td>{landlord.givenName}</td>
                                                        <td>
                                                            <button onClick={() => deleteLandlord(landlord.id)} className="nc-landlord-at-btn">Delete</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="nc-add-tenant">
                                <div className="nc-add-t-button" onClick={addTenant}>+</div>
                                    <p>Add Tenant</p>
                                    <div className="nc-tenant-array-table">
                                        <table className="nc-tenant-at-table">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Tenant</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tenants.map(tenant => (
                                                    <tr key={tenant.id}>
                                                        <td>{tenant.id}</td>
                                                        <td>{tenant.t_givenName}</td>
                                                        <td>
                                                            <button onClick={() => deleteTenant(tenant.id)} className="nc-tenant-at-btn">Delete</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            <DashboardFooter />
        </div>
    );
}

export default NewClient;
